import React, { useState, useEffect } from "react";
import { useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { useApiRequest } from "../../../lib/http-client";
import container from "../../../container";
import {
  ActivityPlayer,
  Loader,
  useSlider,
} from "@transfr-inc/dashboard-components";
import IHActivityView from "./ih-activity-view";
import OohContent from "./ooh-activity-view";
import { ContentType } from "../../../models/lesson-type";

import "./index.scss";

const ActivityView = () => {
  const { courseService } = container;
  const { currentUser } = useStoreState((store) => store.account);

  const history = useHistory();
  const lessonId = history.location.state?.lessonId;
  const activityIndex = history.location.state?.activityIndex;
  const breadcrumb = history.location.state?.breadcrumb;
  const courseId = history.location.state?.courseId;

  const [mappedLesson, setMappedLesson] = useState([]);
  const [loadingLesson, setLoadingLesson] = useState(true);

  const { response: lesson } = useApiRequest(() =>
    courseService.getLessonDetails(lessonId)
  );

  const oohLaunchParameters = {
    actor: {
      account: {
        homePage: `${window.location.protocol}//${window.location.hostname}`,
        name: `${currentUser?.userId}`,
      },
      objectType: "Agent",
    },
    endpoint: process.env.LRS_ENDPOINT,
    auth: process.env.LRS_AUTH,
  };

  useEffect(() => {
    if (lesson?.length && loadingLesson) {
      lesson.map((activity) => {
        if (
          activity.content_type === ContentType.OutOfHeadset.id ||
          activity.content_type === ContentType.Assessment.id
        ) {
          activity.status = "incomplete";
          activity.children = () => (
            <OohContent
              oohLaunchParameters={oohLaunchParameters}
              data={activity}
            />
          );
          return activity;
        } else if (activity.content_type === ContentType.InHeadset.id) {
          activity.status = "incomplete";
          activity.children = () => (
            <IHActivityView
              title={activity.title}
              courseId={courseId}
              description={activity.description}
              timeToComplete={activity.est_duration}
            />
          );
        } else {
          activity.status = "incomplete";
          return activity;
        }
      });

      setMappedLesson(lesson);
      setLoadingLesson();
    }
  }, [lesson, loadingLesson]);

  console.log({ lessonId, lesson, mappedLesson });

  const links = breadcrumb ? breadcrumb.split(",") : [];

  const totalSlides = mappedLesson?.length;

  const slider = useSlider({ totalSlides, initialIndex: activityIndex });

  const handleNext = () => {
    slider.nextSlide();
  };

  const handlePrev = () => {
    slider.previousSlide();
  };

  const handleLinkClick = () => {
    history.push({
      pathname: "my-work",
      state: {
        courseId: courseId,
      },
    });
  };

  return loadingLesson ? (
    <Loader />
  ) : (
    <ActivityPlayer
      slideData={mappedLesson}
      totalSlides={totalSlides}
      handleLinkClick={handleLinkClick}
      links={links}
      slider={slider}
      handleNext={handleNext}
      handlePrev={handlePrev}
    ></ActivityPlayer>
  );
};

export default ActivityView;
